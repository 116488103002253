import React from "react"
import F6_full_bg from "../assets/images/F6_full_bg.png"
import Left_upward_arrow from "../assets/images/Left_upward_arrow.png"
import Right_upward_arrow from "../assets/images/Right_upward_arrow.png"
import Step_1_bg_image from "../assets/images/Step_1_bg_image.png"
import { Layout } from "../components/layout"

const Product = () => {
  return (
    <Layout>
      <header className="pb-3">
        <div className="container text-light">
          <div className="row">
            <div className="col-md-5">
              <h1>Leads Through Wishlist Widget</h1>
              <p>
                Growing your messenger list has never been easier. Our partner
                app qucikly and effortlessly subscribe users to your messenger
                list
              </p>
            </div>
            <div className="col-md-7 pt-3 text-sm-center">
              <div className="position-relative">
                <img
                  src={F6_full_bg}
                  className="mw-100 w-sm-auto"
                  style={{
                    minWidth: 200,
                    minHeight: 306,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </header>
      <div className="container text-center p-3 p-sm-5 blueText">
        <h1 className="font-weight-normal">How it Works?</h1>
        <p className="mb-0">
          Send Abandoned Cart Remainder to Customer. Retarget Abandoned
          Customers and decrease cart abandoned rate and increase revenue.
        </p>
      </div>
      {/* Page Inner Image Start */}
      <div className="container step-one position-relative">
        <div className="d-flex">
          <div className="col-md-10 col-sm-12 col-12 mr-auto">
            <img src={Step_1_bg_image} className="mw-100 d-none d-md-block" />
            <img
              src="res/Step 1 bg image cropped.jpg"
              className="mw-100 d-md-none"
            />
            <div className=" card image-overlay-card rounded  px-3 py-2 Small shadow">
              <div className="card-body  featureText position-relative">
                <p className="featureNumbers">1</p>
                <h4 className="font-weight-normal">Activate For Your Shop</h4>
                <p className="descr">
                  Send Abandoned Cart Remainder to Customer. Retarget Abandoned
                  Customers and decrease cart abandoned rate
                </p>
                <div className="position-relative text-center">
                  <button
                    type="button"
                    className="btn btn-primary btn-sm  orangeBG ml-auto activate-widget"
                  >
                    Activate Now
                  </button>
                </div>
                <img
                  className="left-arrow d-none d-sm-block"
                  src={Left_upward_arrow}
                  width="65px"
                />
                <img
                  className="right-arrow d-sm-none"
                  src={Right_upward_arrow}
                  width="65px"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container step-two position-relative product-second-step">
        <div className="d-flex">
          <div className="col-md-10 col-sm-12 col-12 ml-auto">
            <img
              src={Step_1_bg_image}
              className="mw-100 d-none d-md-block float-right"
            />
            <img
              src="res/Step 2 bg image cropped.jpg"
              className="mw-100 d-md-none"
            />
            <div className=" card image-overlay-card rounded px-3 py-2 Small shadow">
              <div className="card-body  featureText position-relative">
                <p className="featureNumbers">2</p>
                <h4 className="font-weight-normal">Customise , as you like</h4>
                <p className="descr">
                  Send Abandoned Cart Remainder to Customer. Retarget Abandoned
                  Customers and decrease cart abandoned rate
                </p>
                <div className="position-relative text-center">
                  <button
                    type="button"
                    className="btn btn-primary btn-sm  orangeBG ml-auto activate-widget"
                  >
                    Make Changes
                  </button>
                </div>
                <img
                  className="right-arrow"
                  src={Right_upward_arrow}
                  width="65px"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Page Inner Image End */}
      <div id="integration" className="my-5 mx-auto text-center blueText">
        <h5 className="font-weight-normal py-3">
          Interested? Activate Wishlist Widget Now.
        </h5>
        <div className="position-relative">
          <button
            type="button"
            className="btn btn-primary orangeBG ml-auto activate-widget"
          >
            Activate Now
          </button>
        </div>
      </div>
    </Layout>
  )
}

export default Product
